<template>
  <div id="brand">
    <div id="breadcrumb">
      <div class="breadcrumb-con">
        <p>您的位置：</p>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item to="/Home">首页</el-breadcrumb-item>
          <el-breadcrumb-item>全部品牌</el-breadcrumb-item>
        </el-breadcrumb>
        <svg-icon icon-class="ic-close" @click="$router.push('/Home')" />
      </div>
    </div>
    <div class="title">
      <a
        v-for="(list, index) in letterSearch"
        :key="index"
        @click="scrolltoinsdent(list)"
        >{{ list }}</a
      >
    </div>
    <div
      class="list"
      v-for="(list, index) in A_Z"
      :key="index"
      :id="list"
      :ref="list"
    >
      <div class="left">{{ list }}</div>
      <div class="right">
        <div
          class="brand"
          @click="toBrandGoods(item.id, item.brandName)"
          v-for="(item, idx) in Brand[index]"
          :key="idx"
        >
          <img v-lazy="item.logo" alt="" :key="item.id" style="object-fit: contain" />
          <div class="mask">点击进入</div>
          <el-tooltip
            :content="item.brandName"
            placement="bottom"
            effect="light"
            :manual="item.brandName.length < 8"
            :open-delay="500"
          >
            <p>{{ item.brandName }}</p>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "all-brand",
  data() {
    return {
      letterSearch: [
        "#",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      A_Z: "",
      Brand: "",
    };
  },
  created() {
    this.getBrandInitialsList();
  },
  mounted() {},
  methods: {
    async getBrandInitialsList() {
      const data = await this.$API.brand.BrandInitialsList({
        object: {
          brandInitials: "",
        },
      });
      console.log(data.data.data);
      console.log(Object.keys(data.data.data));
      this.A_Z = Object.keys(data.data.data).map((keys) => {
        return keys.toUpperCase();
      });
      this.Brand = Object.values(data.data.data);
    },

    toBrandGoods(id, brandName) {
      this.$router.push({
        path: `BrandGoods`,
        query: {
          brandId: id,
          brandName: brandName,
        },
      });
    },
    //锚点定位
    scrolltoinsdent(item) {
      this.toMove = false;
      let queryId = document.getElementById(item);
      this.activeStep = item;
      new Promise(function (resolve, reject) {
        resolve("成功");
        queryId.scrollIntoView(true);
      }).then((res) => {
        this.toMove = true;
      });
    },
  },
};
</script>

<style>
#Brand {
  background: #f5f5f5 !important;
}
</style>
<style lang="scss" scoped>
#brand {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;
  border-top: 1px solid var(--subjectColor);
  width: 100%;
  #breadcrumb {
    background: #f5f5f5;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    .breadcrumb-con {
      width: 1200px;
      height: 55px;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: #999;
      }
      ::v-deep.el-breadcrumb {
        span {
          color: #999;
        }
        .el-breadcrumb__inner {
          font-weight: normal;
        }
        .el-breadcrumb__inner:hover {
          color: var(--subjectColor);
        }
      }
      ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
        color: #333;
        cursor: default;
      }
      .svg-icon {
        margin-left: 4px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
      .svg-icon:hover {
        color: #000;
      }
    }
  }
  .title {
    width: 1200px;
    height: 40px;
    background: #f5f6f8;
    border: 1px solid #eeeeee;
    display: flex;
    line-height: 40px;
    justify-content: space-around;
    a {
      font-size: 14px;
      font-family: Arial;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
      width: 26px;
      text-align: center;
    }
  }
  .list:last-child {
    margin-bottom: 30px;
  }
  .list {
    width: 1200px;
    display: flex;
    border: 1px solid #eeeeee;
    border-top: none;
    background: #fff;
    .left {
      width: 57px;
      height: 126px;
      text-align: center;
      line-height: 126px;
      font-family: Arial;
      color: #666666;
    }
    .right {
      width: 1140px;
      display: flex;
      flex-wrap: wrap;
      .brand {
        width: 118px;
        height: 126px;
        margin: 0 35px;
        cursor: pointer;
        position: relative;
        img {
          width: 118px;
          height: 64px;
          margin: 18px 0 6px 0;
        }
        p {
          font-size: 14px;
          color: #333333;
          text-align: center;
          width: 118px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .mask {
          position: absolute;
          width: 118px;
          height: 64px;
          opacity: 0.35;
          background: rgba($color: #000000, $alpha: 0.35);
          text-align: center;
          line-height: 64px;
          font-size: 12px;
          color: #ffffff;
          opacity: 0;
          top: 18px;
          transition: all 0.3s;
        }
      }
      .brand:hover .mask {
        opacity: 1;
      }
    }
  }
}
</style>